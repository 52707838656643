import React, {useEffect, useState} from "react";
import '../../App.css';

function DeleteEmployer (){
    return(
    <>
    <div className="areaConteudo">
        <h2>Exluir Perfil de Colaborador</h2>
        <h4>Selecione o perfil</h4>


    </div>
    </>    
    )

} export default DeleteEmployer 