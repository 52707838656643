import {React, useState} from "react";
import {  Salved } from './../components/style';
import { Form, FormGroup, Button, Container } from 'react-bootstrap';

import '../App.css';
//import api from "../components/api";
import Axios from "axios";


function CadastroPJ(){

    const [razaoSocial, setRazaoSocial] = useState("");
    const [nameFantasi, setNameFantasi] = useState("");
    const [Cnpj, setCnpj] = useState("")
    const [telefone, setTelefone] = useState("");
    const [Email, setEmail] = useState("")    
    const [street, setStreet] = useState("")
    const [numberAdress, setNamberAdress] = useState("")
    const [City, setCity] = useState("")
    const [State, setState] = useState("")
    const [Cep, setCep] = useState("")
    const [country, setcountry] = useState("")
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const enterprize = {
        'RazaoSicial': razaoSocial,
        'NomeFantasia': nameFantasi,
        'cnpj': Cnpj,
        'Telefone': telefone,
        'Email': Email,
        'Rua': street,
        'Cidade': City,
        'Estado': State,
        'Pais':country,
        'CEP': Cep,
        'Senha': password

    };
    

    function salved(){

        if(password === passwordConfirm ){
            Axios.post("http://177.153.58.24:4001/api/enterprise",{
                razao_social: enterprize.RazaoSicial,
                nome_fantasia: enterprize.NomeFantasia, 
                cnpj: enterprize.cnpj,
                telephone: enterprize.Telefone,
                email: enterprize.Email,
                street: enterprize.street, 
                city: enterprize.Rua,
                state: enterprize.Estado,
                pais: enterprize.Pais,
                cep: enterprize.CEP,
                senha: enterprize.Senha
                
               }).then((response) => {
                   alert(response.data.msg)
                   console.log(response)
               });       

        } 
        else{
            alert("A confrimação de senha não é igual a senha digitada")
        
        }   
           
        
    }



    return(
        <div className="areaConteudo">
            <Container>
            <Form>
            <h3>Use nossos Serviços e contrate o profissional ideal para suas demandas</h3>
            <br/><br/>
            <Form.Label >Razão Social </Form.Label>
            <Form.Control className="me-auto" name="name" type='text' value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)}/>
            <Form.Label>Nome Fantasia</Form.Label>
            <Form.Control name="name" type='text' value={nameFantasi} onChange={(e) => setNameFantasi(e.target.value)}  />
            <Form.Label>CNPJ</Form.Label>
            <Form.Control nome='cnpj' type='text' value={Cnpj} onChange={(e) => setCnpj(e.target.value)} />
            <Form.Label>Telefone</Form.Label>
            <Form.Control name="telefone" type='text' value={telefone} onChange={(e) => setTelefone(e.target.value)} />
            <Form.Label>Email</Form.Label>
            <Form.Control name="email" type='email' value={Email} onChange={(e) => setEmail(e.target.value)} />
            <br/><br/>
            <h3>Endereço</h3>
            <Form.Label>Rua</Form.Label>
            <Form.Control name="street" type='text' value={street} onChange={(e) => setStreet(e.target.value)} />
            <Form.Label>Numero</Form.Label>
            <Form.Control name="nnumber" type='text' value={numberAdress} onChange={(e) => setNamberAdress(e.target.value)}  />
            <Form.Label>Cidade</Form.Label>
            <Form.Control name="city" type='text' value={City} onChange={(e) => setCity(e.target.value)} />
            <Form.Label>Estado</Form.Label>
            <Form.Control name="state" type='text' value={State} onChange={(e) =>setState(e.target.value)} />
            <Form.Label>País</Form.Label>
            <Form.Control name="country" type='text' value={country} onChange={(e) => setcountry(e.target.value)} />
            <Form.Label>CEP</Form.Label>
            <Form.Control name="cep" type='text' value={Cep} onChange={(e) => setCep(e.target.value)} />
            <Form.Label>Cadastre uma senha</Form.Label>
            <Form.Control name="password" type='password' value={password} onChange={(e) => setPassword(e.target.value)} /> 
            
            <Form.Label>Confirme sua senha</Form.Label>
            
            <Form.Control name="password" type='password' value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
            <br/> 

            <Button variant="success" onClick={salved} >Salvar</Button>

            </Form>
            </Container>
          



        </div>
    );
}

 export default CadastroPJ