import {React, useState, useEffect }from "react";
import '../App.css';
import {RegisterExit} from './style';
import api from "./api";
import { Table } from "react-bootstrap";
import Loader from "./loading/Loader";


function HistoryPointer(){
let [onPointer, SetPointer] = useState([])
let ID_Employer = localStorage.getItem('userId');
const [removeLoader, setRemoveLoader] = useState(false)

useEffect(() =>{
       
    api.get("api/viewPointer", {
        params:{'employer_id': ID_Employer}
    }).then(({data}) =>{
        SetPointer(data);
        setRemoveLoader(true)
    });

}, [])

    return(<div>
    <h3>Histórico de Registro de Ponto</h3>
    <Table striped bordered hover>
        <thead>
            <th>ID de Operação</th>
            <th>Data</th>
          
            <th>Entrada Primeiro Turno</th>
            <th>Saida Primeiro Turno</th>
            <th>Entrada Segundo Turno</th>
            <th>Saída Segundo Turno</th>
        </thead>
        <tbody>
        
            {onPointer.map(item =>(
            <tr key={item.id}>
                <td>{item.id}</td>
            <td>{item.dia}</td>
           
            <td>{item.entrada_1_period}</td>
            <RegisterExit>{item.saida_1_period}</RegisterExit>
            <td>{item.entrada_2_period}</td>
            <RegisterExit>{item.saida_2_priod}</RegisterExit>
           </tr> ))
           }  
           {!removeLoader && <td rowSpan={8} style={{alignItems: 'center'}}> <Loader/></td>}
           </tbody>      
    </Table>

    </div>)
}

export default HistoryPointer