import React from "react";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'

function PdfVHolerit(itens){
    pdfMake.vsf = pdfFonts.pdfMake.vfs;

    const reportTitle= [];

    const details = [];

    const rodape = [];

    const docDefinitions = {
        pageSize: 'A4',
        psgeMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: [rodape]
    }

    pdfMake.createPdf(docDefinitions).download();

}
export default PdfVHolerit