import React, { useEffect, useState } from "react";
import '../../App.css';
import { Accordion, Button } from "react-bootstrap";
import Loader from "../../components/loading/Loader";
import api from "../../components/api";
import Axios from "axios";





function ViewMessage(){
    const [mensagens, setMensagens] = useState([]);
    const [removeLoader, setRemoveLoader] = useState(false);
    
   

    useEffect(() =>{
        Axios.get('http://177.153.58.24:4001/api/readMessage')
        .then(({data}) =>{
            setMensagens(data);
            console.log(data);
            console.log(mensagens)
        })

    },[]);
    // modificação status de leitura de mensagem
  // "http://localhost:4000/api/delMenssagem"
    
  const handleDel = (id) => {
    Axios.delete('http://177.153.58.24:4001/api/delMenssagem',
     JSON.stringify({id}),
    {
        headers:{'Content-Type': 'application/json'}
    })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

    function handleReader(id){
        return(
            alert("Marcar Como lida a mensagem " + id)
        )

    };

    return(
        <>
        <div className="areaConteudo">

        <h3>Vizualisar menssagens</h3>
      
        {mensagens.map(Item =>(
            <div key={Item.id}>
              <Accordion defaultActiveKey={Item.id}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><strong style={{marginRight:3}}> Data: </strong> {Item.dia} <strong style={{marginLeft:3, marginRight:3}}> Nome: </strong> {Item.nome}</Accordion.Header>
                    <Accordion.Body>
                        <strong>Titulo: {Item.assunto}</strong>
                        <br/>
                        <strong>Setor: {Item.setor}</strong>
                        <hr/>
                        {Item.menssagem}
                        <hr/>
                        <h4>Contato:</h4>
                        <h6>{Item.telephone}</h6>
                        <h6>{Item.email}</h6>
                        

                        <Button variant="outline-success" style={{marginRight: 20}} onClick={(e) => handleReader(Item.id, e) }>Marcar como visto </Button>
                        <Button variant="outline-danger" onClick={(e) => handleDel(Item.id, e)}>Excluir</Button>
                        
                        
                    </Accordion.Body> 
                                      
                        
                </Accordion.Item>
                
              </Accordion>

            </div>        
        ))}
      


        </div>
        

        </>
    )
}
export default ViewMessage