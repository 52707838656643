import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import '../../App.css'

function Reembolso() {
    const nome = localStorage.getItem("name");
    const setor = localStorage.getItem("sector");
    const [identify, setIdentfy]  = useState('');
    const [description, setDescription] = useState("");
    const [value, setValue] = useState("");
    const [document, setDocument] = useState("");
    return(
        <>
        <div className="areaConteudo">
        <h2> Adicionar Pedido de Reembolso</h2>
        <br/>
        <span> <h4>Nome: {nome}  Setor: {setor}</h4>  </span>
        
        <Form>       
        <Form.Group>
            <Form.Label>Identificação</Form.Label>
            <Form.Control type="text" value={identify} onChange={(e) => setIdentfy(e.target.value)} />
            <Form.Label>Descrição</Form.Label>
            <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)}/>
            <Form.Label>Valor</Form.Label>
            <Form.Control type="number" value={value} onChange={(e) => setValue(e.target.value)} />
            <Form.Label>Documento</Form.Label>
            <Form.Control type="file" value={document} onChange={(e) => setDocument(e.target.value)} />
        </Form.Group>
        <br/>
        <Button variant="success">Enviar</Button>
        </Form>

        </div>

        </>
    )
} 
export default Reembolso