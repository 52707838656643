import React, {useState} from "react";
import { AreaConteudo } from "../components/style";
import '../App.css';
import LoginPj from "../login/LoginPj";
import LoginEmployer from "../login/LoginEmployer";

function Home() {
  const [ opcao, setOpcao] = useState(1);
  

function Opcoes(){
    if(opcao == 1){
        return(
            <LoginPj/>
        )

    }
    else 
    if(opcao == 2){
        return(
            <LoginEmployer/>
        )

    }
  
}



    return(
        <>
        
        <div className="areaConteudo">
           <select value={opcao} onChange={(e)=> setOpcao(e.target.value)}>
            <option value='1'>Empresas</option>
            <option value='2'>Colaboradores</option>
       

           </select>
        <Opcoes/>
        </div>
        
        </>
    )
}

export default Home