import {React, useEffect, useState} from "react";
import api from "../../components/api";
import  '../../App.css';
import { Axios } from "axios";


function Vholerite(){

    const IdFuncionay = localStorage.getItem("userId")

    const [informations, setInformations] = useState([]);



    useEffect(() =>{       
        api.get("api/viewHolerit", {
            params:{'employer_id': IdFuncionay}
        }).then(({data}) =>{
            setInformations(data)
        });
    
    }, [])
    


    return(
        <>
        <div className="areaConteudo">
            <h3>Holerite</h3>
            <h4>Escolha um mês para consultar</h4>
            {informations.length>1 &&
            <div>
                
                {informations.map(item =>(
                    <summary key={item.id}>{item.period}</summary>
                ))}
            </div>
}
{
    informations.length<1 &&
    <div><h3>Sem informações</h3></div>
}

        </div>

        </>
    );
} export default Vholerite
