import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
    key: 'idKey',
    id:'idUser',
    name: 'user',   
    typeUSer:'',
    isLogged: false,
    initialState:{
        key:'',
        id:'',
        name: 'user',        
        typeUSer: '',
        isLogged: false,
    },
    reducers:{
        changeUSer(state, {payload}) {
            return {...state, isLogged: true, key:payload, id:payload, name:payload}
        },
        logout(state){
            return {...state, isLogged: false, key:'', id:'', name: ''}
        }
    }
})

export const {changeUSer, logout} = slice.actions
export const selectUser = state => state.name
export default slice.reducer