import {React, useState} from "react";
import '../App.css';

import { Salved } from "../components/style";

import { Enunciate, Input} from '../components/style';





function CadastroCAndidato() {

    const [fullName, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [cellphone, setCellphone] = useState('')
    const [docs, setDocs] = useState()
    const [newHabilities, setNewHabilitie] = useState("")
  //  const [habilities, setHabilities] = useState([]);
    const [timeExperience, setTimeExperience] = useState ()
   /* const [newTimeExperience, setNewTimeExperience] = useState([])*/
    const [linkedin, setLinkedin] = useState('')
    const[faceboock, setFaceboock] = useState('')
    const [nascimento, setNascimento] = useState('')
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pais, setPais] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
     

    // função para adição de habilidades e tempo de experiência de cada uma
 

   /* function handleradicioner(){
      const habilitie = {
        id: Math.floor(Math.random() * 1000),
        value: newHabilities
      };

      setHabilities(onldlist => [...onldlist, habilitie]);
      setNewHabilitie("")

      console.log(habilities)

      
     

      
    } */
    
    //validação de formularios com yup

   
 // verificação de campos a serem preenchidos
    function handleFormuler(){
        if(fullName === '' || email === '' || cellphone === ''){
          return(  alert("campos sem preencher"))
        }
        else 
        return(alert("tudo certo"))
    }
 // função handleForm envia os dados via requisição para o bacckend

     return(
        <div className="areaConteudo"> 
       <h3> Cadastre-se em nossa plataforma </h3>
       <br/><br/>
        <form >
            <Enunciate>Nome completo *</Enunciate>
            <Input
            name="name"
            type='text'
            value={fullName}
            onChange={(e) => setFullname(e.target.value)}
             />
              <Enunciate>Celular *</Enunciate>
              <Input
              name="cellphone"
              type='tel'
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
               />
             <Enunciate>E-mail *</Enunciate>
             <Input
             name="email"             
             type='email'
             value={email}
             onChange={(e) => setEmail(e.target.value)}
              />
            <Enunciate>LinkedIn *</Enunciate>
            <Input
            name="linkedin"
            type='url'
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}               
              />
            <Enunciate>Faceboock </Enunciate>
            <Input
            name="faceboock"
            type='url'
            value={faceboock}
            onChange={(e) => setFaceboock(e.target.value)}
            />
              <Enunciate>Anexar Curriculo</Enunciate>
              <Input
              name="curriculo"
              type='file'
              value={docs}
              onChange={(e) => setDocs(e.target.value)}
               />
               <h4>Conte um pouco sobre suas experiências</h4>

               <Enunciate>Adicionar habilidades</Enunciate>
               <Input
               name="habilidades"
               type='text'
               value={newHabilities}
               onChange={(e) => setNewHabilitie(e.target.value)} 
               />

               <Enunciate>Tempo de experiência em Anos</Enunciate>
               <Input
               name="experiências"
               type='number'
               value={timeExperience}
               onChange={(e) => setTimeExperience(e.target.value)}
               />

               <h4>Vamos completar algumas informações</h4>
               <Enunciate>Data de nascimento</Enunciate>
               <Input
               name="nascimento"
               type='date'
               value={nascimento}
               onChange={(e)=> setNascimento(e.target.value)}
                />
                <Enunciate>Rua</Enunciate>
                <Input
                name="street"
                type='text'
                value={street}
                onChange={(e) =>setStreet(e.target.value)}
                 />
                 <Enunciate>Cidade</Enunciate>
                 <Input
                 name="city"
                 type='text'
                 value={city}
                 onChange={(e) => setCity(e.target.value)}
                  />
                  <Enunciate>Estado</Enunciate>
                  <Input
                  name="state"
                  type='text'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                   />
                   <Enunciate>Pais</Enunciate>
                   <Input
                   name="country"
                   type='text'
                   value={pais}
                   onChange={(e) => setPais(e.target.value)}
                    />
                    <h4>Agora Vamos Cadastrar a Sua senha</h4>
                    <Enunciate>Senha</Enunciate>
                    <Input
                    name="password"
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                    />
                    <Enunciate>Confirme sua senha</Enunciate>
                    <Input
                    name="password"
                    type='password'
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                    <br/>
               <Salved onClick={handleFormuler}>Salvar</Salved>
        </form>       
        </div>
    );
} 
  export default CadastroCAndidato