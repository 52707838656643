import React from "react";
import api from "../../components/api";
import { useState, useEffect } from "react";
import {Salved, Avatar, Enunciate, Input, AreaHistory} from '../../components/style';
import RegisterPointer from "../../components/RegisterPointer";
import HistoryPointer from "../../components/HistoryPointer";
import '../../App.css';


function PointerRegister(){
    const hora = new Date().toLocaleTimeString();
    let name = localStorage.getItem('name')
    
   

    
        var dateString = new Date().toLocaleString("pt-BR", {timezone: 'America/Sao_Paulo'});
        var formattesString = dateString.replace(" , ", " - ");
        var time = formattesString;
        setInterval(time, 1000)

        const [hour, setHour] = useState({time});
        const funcionary = localStorage.getItem("userId");

        
        let [employer, setEmployer] = useState([]) 
    
        useEffect(()=>{
            api.get("/api/profileEmployer", {
                params:{id: funcionary }
            }).then(({item}) =>{
                setEmployer(item);
            })
    
        },[])
        

    

    return(<>
    <div className="areaConteudo">

    <h3>Registro de ponto</h3>
    
        <div >
    <Avatar/> <Enunciate>Nome do Funcionário: {name} </Enunciate>
    </div>
  
    
   

    <AreaHistory>
    <RegisterPointer/>
    </AreaHistory>
    
    


    <br/>
    <AreaHistory> <HistoryPointer/> </AreaHistory>
    


    </div>

    </>)
}
 export default PointerRegister