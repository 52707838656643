import {React, useEffect, useState} from 'react';
import { Form, Button } from 'react-bootstrap';
import Axios from 'axios';


function Contact(){
    const [opcao, setOpccao] = useState ('comercial');
    const [nome, setNome] = useState();
    const [telephone, setphone] = useState();
    const [email, setEmail] = useState();
    const [assunto, setAssunto] = useState();    
    const [menssagem, setMenssagem] = useState();
    const [documento, setDocumento] = useState();    
    const [empresa, setEmpresa] = useState();
    const reade = false;
    var dia = new Date();  
    var setor = opcao;
    
    const handleMenssage = async (e) =>{
        e.preventDefault()
        try{
        const response = await Axios.post("http://ec2-15-229-46-205.sa-east-1.compute.amazonaws.com:3333/api/novaMenssagem",
        JSON.stringify({dia, nome, telephone, email, setor, assunto, menssagem, documento, empresa, reade  }),
        {
            headers:{'Content-Type': 'application/json'}
        })
        alert(response.data);
        window.location.reload()
        } catch(error){
            alert(error)
        }      

    }
    return(<>
    <div className='areaConteudo'>        
        <Form.Group>
            <Form.Label>Selecione a área desejada </Form.Label>
            <select value={opcao} onChange={(e) => setOpccao(e.target.value)}>            
                <option value='comercial'>Comercial</option>
                <option value='desenvolvimento'>Desenvolvimento</option>
                <option value='financeiro'>Financeiro</option> 
            </select> <br/>
            <Form.Label >Nome</Form.Label>
                <Form.Control type='text' value={nome} onChange={(e) => setNome(e.target.value)} placeholder='exemplo: João'/>
                <Form.Label>Empresa</Form.Label>
                <Form.Control type='text' name='enterprise' value={empresa} onChange={(e) => setEmpresa(e.target.value)}/>
                <Form.Label>Telephone</Form.Label>
                <Form.Control type='text' name='phone' value={telephone} onChange={(e) => setphone(e.target.value)}/>
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                <Form.Label>Assunto</Form.Label>
                <Form.Control type='text' name='mensage' value={assunto} onChange={(e) => setAssunto(e.target.value)} />
                <Form.Label>Mensagem</Form.Label>
                <Form.Control as='textarea' value={menssagem} onChange={(e) => setMenssagem(e.target.value)}  />
                <Form.Label>Anexar Arquivos</Form.Label>
                <Form.Control type='file' multiple value={documento} onChange={(e) => setDocumento(e.target.value)} />       
            
        </Form.Group>
        <br/><br/>
        <Button onClick={handleMenssage}>Enviar</Button>  
    </div>
    <br/><br/>
    </>)
}
export default Contact
