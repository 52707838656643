import React from "react";
import {Oval } from "react-loader-spinner";

function Loader(){
    return(
        <Oval
        align-self='center'
        height={80}
        width={80}
        color="#01DFD7"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#2EFEF7"
        strokeWidth={2}
        strokeWidthSecondary={2}
      
      />
    )

}
export default Loader