import React from "react";
import "../../App.css";

function FolhaPagamento(){



    return(
        <>
        <h2>Gerar Folha de Pagamento</h2>
        </>
    )


}
export default FolhaPagamento