import React from "react";
import '../../App.css'

function DelCliente(){
    return(<>
    <div className="areaConteudo">
        <h3> Excluir Perfil de Cliente</h3>
    </div>

    </>

    )
} export default DelCliente